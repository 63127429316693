import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Table, Row, Attributes, Expander, List, InfoIcon, WarningIcon, Tabs } from './components';
import { getTemplateCurl, getTemplateJava, getTemplateJavascript, getTemplateNode, getTemplatePerl, getTemplatePython, getTemplatePhp, getTemplateRuby, getTemplateGo, getTemplateC, getTemplateVB, getTemplateGroovy, getTemplateObjectiveC, getTemplateSwift, createTemplateRaw, createTemplateCurl, createTemplateJava, createTemplateJavascript, createTemplateNode, createTemplatePerl, createTemplatePython, createTemplatePhp, createTemplateRuby, createTemplateGo, createTemplateC, createTemplateVB, createTemplateGroovy, createTemplateObjectiveC, createTemplateSwift, updateTemplateRaw, updateTemplateCurl, updateTemplateJava, updateTemplateJavascript, updateTemplateNode, updateTemplatePerl, updateTemplatePython, updateTemplatePhp, updateTemplateRuby, updateTemplateGo, updateTemplateC, updateTemplateVB, updateTemplateGroovy, updateTemplateObjectiveC, updateTemplateSwift } from 'requestExamples/templates';
export const endpoints = [{
  param: 'GET',
  value: '/nodes/templates/{account_name}/My Templates/{entity_definition}',
  subtitle: 'Retrieve template',
  link: '#retrieve-a-template'
}, {
  param: 'PUT',
  value: '/nodes/templates/{account_name}/My Templates',
  subtitle: 'Create template',
  link: '#create-a-template'
}, {
  param: 'POST',
  value: '/nodes/templates/{account_name}/My Templates/{entity_definition}',
  subtitle: 'Modify template',
  link: '#update-a-template'
}];
export const retrieveTemplate = [{
  param: 'GET',
  value: '/nodes/templates/{account_name}/My Templates/{entity_definition}',
  link: '#retrieve-a-template'
}];
export const createTemplate = [{
  param: 'PUT',
  value: '/nodes/templates/{account_name}/My Templates/{entity_definition}'
}];
export const updateTemplate = [{
  param: 'POST',
  value: '/nodes/templates/{account_name}/My Templates/{entity_definition}'
}];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  endpoints,
  retrieveTemplate,
  createTemplate,
  updateTemplate,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "templates"
    }}>{`Templates`}</h1>
    <Row mdxType="Row">
	<div>
		<p>
			Templates allow clients to easily store and organize files for an{' '}
			<a href="/api/entities/introduction" target="_blank">
				entity
			</a>{' '}
			in the customer’s environment (Tax Engagement, Firm, Client, etc.).
			<br />
			<br />
			Its management can be done using the /nodes/template resource.
		</p>
	</div>
	<Table title="Endpoints" rows={endpoints} mdxType="Table" />
    </Row>
    <br />
    <List values={[{
      title: 'Templates can include folders, vaults, and containers.'
    }, {
      title: 'Templates also allow you to specify what documents should be automatically routed to each location within the template.'
    }, {
      title: 'Users can create new templates and use and/or modify preexisting templates created by SmartVault.',
      value: 'When a template is modified, the changes can be subsequently applied to existing folder templates created by those templates.'
    }]} mdxType="List" />
    <br />
    <br />
    <WarningIcon mdxType="WarningIcon" />
All the endpoints related to templates are case sensitive, so be careful when doing the request because if you don't
specify the casing properly, you will get an error object as a result of the request.
    <br />
    <br />
    <br />
    <br />
    <hr style={{
      "width": "50%"
    }} />
    <br />
    <br />
    <h2 {...{
      "id": "the-template-object"
    }}>{`The template object`}</h2>
    <p>{`Some of the template object attributes (vaults, folders, containers and groups) refers to the SmartVault types specified below.`}</p>
    <Expander title="Attributes" mdxType="Expander">
	<Attributes items={[{
        name: 'applies_to',
        type: 'string (optional)',
        description: 'The entity that the template will apply to; it must be the API Name of the entity (e.g. the API name for an accounting client entity is “SmartVault.Accounting.Client”).\r\nMore information about entities on its specific documentation page.'
      }, {
        name: 'api_name',
        type: 'string',
        description: 'What the template will be stored as in the API. It will serve as a unique id and must be unique within the organizational unit.'
      }, {
        name: 'label',
        type: 'string',
        description: 'User friendly name for the template. This is how the template name will appear in the client portal.'
      }, {
        name: 'vaults',
        type: 'List<TemplateVault> (optional)',
        description: 'Structure of vaults in this template.'
      }, {
        name: 'folders',
        type: 'List<TemplateFolder> (optional)',
        description: 'Structure of folders in this template.'
      }, {
        name: 'containers',
        type: 'List<TemplateContainer> (optional)',
        description: 'Structure of containers in this template.'
      }, {
        name: 'groups',
        type: 'List<TemplateGroup> (optional)',
        description: 'Defines what groups the template will be available to.'
      }]} mdxType="Attributes" />
    </Expander>
    <br />
    <br />
    <br />
    <h3 {...{
      "id": "the-templatevault-object"
    }}>{`The TemplateVault object`}</h3>
    <hr style={{
      "width": "50%"
    }} />
    <p>{`This is the vault that will contain all of the data associated with this Entity.
At most, there can only be one vault as the root.`}<br />{`
Currently only SmartVault.Accounting.Client and SmartVault.Core.UserAssociation takes advantage of vaults.`}</p>
    <Expander title="vault attributes" mdxType="Expander">
	<Attributes items={[{
        name: 'name',
        type: 'string (optional)',
        description: 'The name of the vault.'
      }, {
        name: 'acl',
        type: 'UpdateACL (optional)',
        description: 'Access control list for the vault.'
      }, {
        name: 'id',
        type: 'string',
        description: 'Unique identifier for the vault. It must be unique within the entire template hierarchy.'
      }, {
        name: 'relationship',
        type: 'string (optional)',
        description: 'Defines any entities that are related to the Entity stored by this template that should be stored here.'
      }, {
        name: 'folders',
        type: 'List<TemplateFolder> (optional)',
        description: 'Structure of folders that are children of this vault.'
      }, {
        name: 'notifications',
        type: 'UpdateNotificationList (optional)',
        description: 'Notifications for the vault.'
      }]} mdxType="Attributes" />
    </Expander>
    <br />
    <br />
    <br />
    <h3 {...{
      "id": "the-templatefolder-object"
    }}>{`The TemplateFolder object`}</h3>
    <hr style={{
      "width": "50%"
    }} />
    <p>{`This is the folder that will contain all of the data associated with the entity.
At most, there can only be one folder as the root.`}<br />{`
Currently only SmartVault.Accounting.TaxEngagement, as well as other engagements, take advantage of folders.`}</p>
    <Expander title="folder attributes" mdxType="Expander">
	<Attributes items={[{
        name: 'name',
        type: 'string (optional)',
        description: 'The name of the folder.'
      }, {
        name: 'acl',
        type: 'UpdateACL (optional)',
        description: 'Access control list for the folder.'
      }, {
        name: 'id',
        type: 'string',
        description: 'Unique identifier for the folder. It must be unique within the entire template hierarchy.'
      }, {
        name: 'relationship',
        type: 'string (optional)',
        description: 'Defines any entities that are related to the Entity stored by this template that should be stored here.'
      }, {
        name: 'folders',
        type: 'List<FolderAssociation> (optional)',
        description: 'Structure of folders that are children of this folder.\r\nMore information about this type right below on this section.'
      }, {
        name: 'folder_association',
        type: 'FolderAssociation (optional)',
        description: 'Routing rules associated with this folder.\r\nMore information about this type right below on this section.'
      }, {
        name: 'notifications',
        type: 'UpdateNotificationList (optional)',
        description: 'Notifications for the folder.'
      }]} mdxType="Attributes" />
    </Expander>
    <br />
    <h4 {...{
      "id": "the-folderassociation-object"
    }}>{`The FolderAssociation object`}</h4>
    <p>{`Folder associations consist of tags.
Documents published to the template entity that include these tags will be routed to this location.
Each routing rule should only occur at most once in the entire hierarchy.`}</p>
    <Expander title="folder association attributes" mdxType="Expander">
	<Attributes items={[{
        name: 'tags',
        type: 'List<Tag> (optional)',
        description: 'Designate what documents need to be routed to this location.'
      }]} mdxType="Attributes" />
    </Expander>
    <br />
    <h4 {...{
      "id": "the-tag-object"
    }}>{`The Tag object`}</h4>
    <Expander title="tag attributes" mdxType="Expander">
	<Attributes items={[{
        name: 'value',
        type: 'string',
        description: 'The tag value.'
      }]} mdxType="Attributes" />
    </Expander>
    <br />
    <p>{`Possible tag values are`}</p>
    <Expander mdxType="Expander">
	<List values={[{
        title: 'misc.tags.core.smartvault.com'
      }, {
        title: 'taxsource.tags.accounting.smartvault.com'
      }, {
        title: 'firmtaxsource.tags.accounting.smartvault.com'
      }, {
        title: 'acceptanceletter.efile.tags.accounting.smartvault.com'
      }, {
        title: 'preparerfilecopy.taxreturn.tags.accounting.smartvault.comp'
      }, {
        title: 'reparerreviewcopy.taxreturn.tags.accounting.smartvault.com'
      }, {
        title: 'governmentcopy.taxreturn.tags.accounting.smartvault.com'
      }, {
        title: 'clientcopy.taxreturn.tags.accounting.smartvault.com'
      }, {
        title: 'complete.taxreturn.tags.accounting.smartvault.com'
      }, {
        title: 'nextyearsfiles.tags.accounting.smartvault.com'
      }]} mdxType="List" />
    </Expander>
    <br />
    <br />
    <br />
    <h3 {...{
      "id": "the-templatecontainer-object"
    }}>{`The TemplateContainer object`}</h3>
    <hr style={{
      "width": "50%"
    }} />
    <p>{`These containers are children of the account; in other words, there is automatically a storage element to contain the SmartVault.Accounting.Firm before the firm is created.
Unlike the vaults and folders elements, there can be any amount of containers as the root for a template.`}</p>
    <Expander title="container attributes" mdxType="Expander">
	<Attributes items={[{
        name: 'name',
        type: 'string (optional)',
        description: 'The name of the container.'
      }, {
        name: 'acl',
        type: 'UpdateACL (optional)',
        description: 'Access control list for the container.'
      }, {
        name: 'id',
        type: 'string',
        description: 'Unique identifier for the container. It must be unique within the entire template hierarchy.'
      }, {
        name: 'relationship',
        type: 'string (optional)',
        description: 'Defines any entities that are related to the Entity stored by the template that should be stored here.\r\nFor example, the ‘Clients’ container in a typical template for SmartVault.Accounting.Firm has the relationship set as SmartVault.Accounting.FirmClient.'
      }, {
        name: 'groups',
        type: 'List<TemplateGroups> (optional)',
        description: 'Describes what groups the templates will be available to.'
      }, {
        name: 'vaults',
        type: 'List<TemplateVaults> (optional)',
        description: 'Structure of vaults that are children of this container.'
      }, {
        name: 'containers',
        type: 'List<TemplateContainers> (optional)',
        description: 'Structure of containers that are children of this container.'
      }, {
        name: 'notifications',
        type: 'UpdateNotificationList (optional)',
        description: 'Notifications for the container.'
      }]} mdxType="Attributes" />
    </Expander>
    <br />
    <br />
    <br />
    <h3 {...{
      "id": "the-templategroup-object"
    }}>{`The TemplateGroup object`}</h3>
    <hr style={{
      "width": "50%"
    }} />
    <p>{`A group specifies who has access to the templates. For usage in templates, permissions are restricted to group monikers.`}<br />{`
These group monikers are resolved dynamically by the system to produce groups’ identities.`}</p>
    <Expander title="group attributes" mdxType="Expander">
	<Attributes items={[{
        name: 'moniker',
        type: 'string (optional)',
        description: "Name of the group the way it's stored as in the API."
      }, {
        name: 'name',
        type: 'string (optional)',
        description: 'How the group moniker appears as in the client portal.'
      }, {
        name: 'rid',
        type: 'integer (optional)',
        description: 'Relative ID. Uniquely identifies the group.'
      }, {
        name: 'create',
        type: 'boolean (optional)',
        description: 'whether the template will be accessed by the group.'
      }]} mdxType="Attributes" />
    </Expander>
    <br />
    <p>{`These are the group monikers that are currently supported.`}</p>
    <Expander title="group monikers" mdxType="Expander">
	OrganizationUnit monikers (Can be used in Container, Vault, and Folder nodes):
	<br />
	<br />
	<List inline values={[{
        title: 'AccountAdmins',
        value: 'Administrators. \r\n RID: 1'
      }, {
        title: 'AccountEmployees',
        value: 'Employees. \r\n RID: 18'
      }, {
        title: 'AccountUsers',
        value: 'All users. \r\n RID: 2'
      }, {
        title: 'AccountCustomGroup1',
        value: 'CustomGroup1. \r\n RID: 19'
      }, {
        title: 'AccountCustomGroup2',
        value: 'CustomGroup2. \r\n RID: 20'
      }, {
        title: 'AccountCustomGroup3',
        value: 'CustomGroup2. \r\n RID: 21'
      }]} mdxType="List" />
	<br />
	<br />
	Vault monikers (Can be used in a Vault and Folder node):
	<br />
	<br />
	<List inline values={[{
        title: 'FirmManager',
        value: 'Firm Manager or Vault Manager. \r\n RID: 8'
      }, {
        title: 'FirmEmployees',
        value: 'Firm Employees or Vault Full Access. \r\n RID: 9'
      }, {
        title: 'VaultFullInbox',
        value: 'Vault Full Inbox. \r\n RID: 10'
      }]} mdxType="List" />
	<br />
	<br />
	<List values={[{
        title: 'ClientContacts',
        value: 'Client Contacts or Vault Read Only. \r\n RID: 16'
      }]} mdxType="List" />
    </Expander>
    <br />
    <br />
    <br />
    <br />
    <hr />
    <br />
    <br />
    <h2 {...{
      "id": "template-types"
    }}>{`Template types`}</h2>
    <p>{`The full list of templates available for an account can be fetched executing the request below.`}<br /></p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`https://rest.smartvault.com/nodes/template/SmartVault Account/My Templates?children=1
`}</code></pre>
    <br />
Example of template names retrieval response
    <br /><br />
    <Expander title="templates" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": true
    },
    "message": {
        "name": "My Templates",
        "createdOn": "2020-07-06T14:35:25-05:00",
        "modifiedOn": "2020-07-06T14:35:25-05:00",
        "uri": "/nodes/template/SmartVault Account/My Templates",
        "your_permissions": 68,
        "total_children": 20,
        "children": [
            {
                "name": "Intuit.Accounting.DMS.ClientTemplate2",
                "createdOn": "2020-07-06T14:35:25-05:00",
                "modifiedOn": "2020-07-06T14:35:25-05:00",
                "uri": "/nodes/template/SmartVault Account/My Templates/Intuit.Accounting.DMS.ClientTemplate2",
                "your_permissions": 81
            },
            {
                "name": "Intuit.Accounting.DMS.TaxTemplate2",
                "createdOn": "2020-07-06T14:35:25-05:00",
                "modifiedOn": "2020-07-06T14:35:25-05:00",
                "uri": "/nodes/template/SmartVault Account/My Templates/Intuit.Accounting.DMS.TaxTemplate2",
                "your_permissions": 81
            },
            {
                "name": "Managed Organization",
                "createdOn": "2020-07-06T14:35:25-05:00",
                "modifiedOn": "2020-07-06T14:35:25-05:00",
                "uri": "/nodes/template/SmartVault Account/My Templates/Managed Organization",
                "your_permissions": 81
            },
            {
                "name": "SmartVault.Accounting.AccountingServicesEngagementTemplate",
                "createdOn": "2020-07-06T14:35:25-05:00",
                "modifiedOn": "2020-07-06T14:35:25-05:00",
                "uri": "/nodes/template/SmartVault Account/My Templates/SmartVault.Accounting.AccountingServicesEngagementTemplate",
                "your_permissions": 81
            },
            {
                "name": "SmartVault.Accounting.AccountingServicesMonthlyEngagementTemplate",
                "createdOn": "2020-07-06T14:35:25-05:00",
                "modifiedOn": "2020-07-06T14:35:25-05:00",
                "uri": "/nodes/template/SmartVault Account/My Templates/SmartVault.Accounting.AccountingServicesMonthlyEngagementTemplate",
                "your_permissions": 81
            },
            {
                "name": "SmartVault.Accounting.FinancialServicesEngagementTemplate",
                "createdOn": "2020-07-06T14:35:25-05:00",
                "modifiedOn": "2020-07-06T14:35:25-05:00",
                "uri": "/nodes/template/SmartVault Account/My Templates/SmartVault.Accounting.FinancialServicesEngagementTemplate",
                "your_permissions": 81
            },
            {
                "name": "SmartVault.Accounting.FirmTemplate",
                "createdOn": "2020-07-06T14:35:25-05:00",
                "modifiedOn": "2020-07-06T14:35:25-05:00",
                "uri": "/nodes/template/SmartVault Account/My Templates/SmartVault.Accounting.FirmTemplate",
                "your_permissions": 81
            },
            {
                "name": "SmartVault.Accounting.InvestmentEngagementTemplate",
                "createdOn": "2020-07-06T14:35:25-05:00",
                "modifiedOn": "2020-07-06T14:35:25-05:00",
                "uri": "/nodes/template/SmartVault Account/My Templates/SmartVault.Accounting.InvestmentEngagementTemplate",
                "your_permissions": 81
            },
            {
                "name": "SmartVault.Accounting.PayrollEngagementTemplate",
                "createdOn": "2020-07-06T14:35:25-05:00",
                "modifiedOn": "2020-07-06T14:35:25-05:00",
                "uri": "/nodes/template/SmartVault Account/My Templates/SmartVault.Accounting.PayrollEngagementTemplate",
                "your_permissions": 81
            },
            {
                "name": "SmartVault.Accounting.PayrollMonthlyEngagementTemplate",
                "createdOn": "2020-07-06T14:35:25-05:00",
                "modifiedOn": "2020-07-06T14:35:25-05:00",
                "uri": "/nodes/template/SmartVault Account/My Templates/SmartVault.Accounting.PayrollMonthlyEngagementTemplate",
                "your_permissions": 81
            },
            {
                "name": "SmartVault.Accounting.PayrollQuarterlyEngagementTemplate",
                "createdOn": "2020-07-06T14:35:25-05:00",
                "modifiedOn": "2020-07-06T14:35:25-05:00",
                "uri": "/nodes/template/SmartVault Account/My Templates/SmartVault.Accounting.PayrollQuarterlyEngagementTemplate",
                "your_permissions": 81
            },
            {
                "name": "SmartVault.Accounting.SalesTaxEngagementTemplate",
                "createdOn": "2020-07-06T14:35:25-05:00",
                "modifiedOn": "2020-07-06T14:35:25-05:00",
                "uri": "/nodes/template/SmartVault Account/My Templates/SmartVault.Accounting.SalesTaxEngagementTemplate",
                "your_permissions": 81
            },
            {
                "name": "SmartVault.Accounting.SalesTaxQuarterlyEngagementTemplate",
                "createdOn": "2020-07-06T14:35:25-05:00",
                "modifiedOn": "2020-07-06T14:35:25-05:00",
                "uri": "/nodes/template/SmartVault Account/My Templates/SmartVault.Accounting.SalesTaxQuarterlyEngagementTemplate",
                "your_permissions": 81
            },
            {
                "name": "SmartVault.Accounting.WealthManagementEngagementTemplate",
                "createdOn": "2020-07-06T14:35:25-05:00",
                "modifiedOn": "2020-07-06T14:35:25-05:00",
                "uri": "/nodes/template/SmartVault Account/My Templates/SmartVault.Accounting.WealthManagementEngagementTemplate",
                "your_permissions": 81
            },
            {
                "name": "SmartVault.Core.GeneralAnnualEngagementTemplate",
                "createdOn": "2020-07-06T14:35:25-05:00",
                "modifiedOn": "2020-07-06T14:35:25-05:00",
                "uri": "/nodes/template/SmartVault Account/My Templates/SmartVault.Core.GeneralAnnualEngagementTemplate",
                "your_permissions": 81
            },
            {
                "name": "SmartVault.Core.GeneralMonthlyEngagementTemplate",
                "createdOn": "2020-07-06T14:35:25-05:00",
                "modifiedOn": "2020-07-06T14:35:25-05:00",
                "uri": "/nodes/template/SmartVault Account/My Templates/SmartVault.Core.GeneralMonthlyEngagementTemplate",
                "your_permissions": 81
            },
            {
                "name": "SmartVault.Core.GeneralQuarterlyEngagementTemplate",
                "createdOn": "2020-07-06T14:35:25-05:00",
                "modifiedOn": "2020-07-06T14:35:25-05:00",
                "uri": "/nodes/template/SmartVault Account/My Templates/SmartVault.Core.GeneralQuarterlyEngagementTemplate",
                "your_permissions": 81
            },
            {
                "name": "SmartVault.Core.ProjectEngagementTemplate",
                "createdOn": "2020-07-06T14:35:25-05:00",
                "modifiedOn": "2020-07-06T14:35:25-05:00",
                "uri": "/nodes/template/SmartVault Account/My Templates/SmartVault.Core.ProjectEngagementTemplate",
                "your_permissions": 81
            },
            {
                "name": "SmartVault.Core.UserTemplate",
                "createdOn": "2020-07-06T14:35:25-05:00",
                "modifiedOn": "2020-07-06T14:35:25-05:00",
                "uri": "/nodes/template/SmartVault Account/My Templates/SmartVault.Core.UserTemplate",
                "your_permissions": 81
            },
            {
                "name": "TaxEngagementCustomTemplate",
                "createdOn": "2020-07-06T14:35:25-05:00",
                "modifiedOn": "2020-07-06T14:35:25-05:00",
                "uri": "/nodes/template/SmartVault Account/My Templates/TaxEngagementCustomTemplate",
                "your_permissions": 81
            }
        ]
    }
}
`}</code></pre>
    </Expander>
    <br />
    <br />
    <br />
    <br />
    <hr />
    <br />
    <br />

    <h2 {...{
      "id": "retrieve-a-template"
    }}>{`Retrieve a template`}</h2>
    <Row mdxType="Row">
	<div>
		<p>
			Templates are located in /nodes/template/account_name/My Templates.
			<br />
		</p>
	</div>
	<Table title="Retrieve template" rows={retrieveTemplate} mdxType="Table" />
    </Row>
    <br />
    <br />
    <p><strong parentName="p">{`Parameters`}</strong></p>
    <hr />
    <Attributes items={[{
      name: 'account_name',
      type: 'string',
      description: "The user's account name."
    }, {
      name: 'entity_definition',
      type: 'string',
      description: 'The template name.'
    }]} mdxType="Attributes" />
    <br />
    <p><strong parentName="p">{`Request`}</strong></p>
    <hr></hr>
    <Tabs items={[{
      tabTitle: 'cURL',
      tabInfo: getTemplateCurl
    }, {
      tabTitle: 'Java',
      tabInfo: getTemplateJava
    }, {
      tabTitle: 'Javascript',
      tabInfo: getTemplateJavascript
    }, {
      tabTitle: 'Node',
      tabInfo: getTemplateNode
    }, {
      tabTitle: 'Perl',
      tabInfo: getTemplatePerl
    }, {
      tabTitle: 'Python',
      tabInfo: getTemplatePython
    }, {
      tabTitle: 'PHP',
      tabInfo: getTemplatePhp
    }, {
      tabTitle: 'Ruby',
      tabInfo: getTemplateRuby
    }, {
      tabTitle: 'Go',
      tabInfo: getTemplateGo
    }, {
      tabTitle: 'C#',
      tabInfo: getTemplateC
    }, {
      tabTitle: 'Visual Basic',
      tabInfo: getTemplateVB
    }, {
      tabTitle: 'Groovy',
      tabInfo: getTemplateGroovy
    }, {
      tabTitle: 'Objective-C',
      tabInfo: getTemplateObjectiveC
    }, {
      tabTitle: 'Swift',
      tabInfo: getTemplateSwift
    }]} mdxType="Tabs" />
    <br />
    <p><strong parentName="p">{`Response`}</strong></p>
    <hr></hr>
    <p>{`Returns a `}<a parentName="p" {...{
        "href": "/api/terminology#node-response"
      }}>{`node response object`}</a>{` pointing to the template retrieved.`}</p>
    <Expander title="success object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": true
    },
    "message": {
        "name": "Intuit.Accounting.DMS.ClientTemplate2",
        "createdOn": "2020-07-03T09:38:27-05:00",
        "modifiedOn": "2020-07-03T09:38:27-05:00",
        "uri": "/nodes/template/SmartVault Account/My Templates/Intuit.Accounting.DMS.ClientTemplate2",
        "your_permissions": 81
    }
}
`}</code></pre>
    </Expander>
    <br />
    <p>{`Returns an `}<a parentName="p" {...{
        "href": "/api/errors#error-object"
      }}>{`error object`}</a>{` if any of the required parameters are wrong.`}</p>
    <Expander title="error object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": false,
        "error_code": -520093684,
        "error_text": "Object not found.",
        "instance_guid": "c213ad0d-2a43-488f-869e-d88586bcd884",
        "status_code": 404
    }
}
`}</code></pre>
    </Expander>
    <br />
    <br />
    <br />
    <br />
    <hr />
    <br />
    <br />

    <h2 {...{
      "id": "create-a-template"
    }}>{`Create a template`}</h2>
    <Row mdxType="Row">
	<div>SmartVault allows the creation of new templates for an account.</div>
	<Table title="Create a template" rows={createTemplate} mdxType="Table" />
    </Row>
    <br />
    <p><strong parentName="p">{`Parameters`}</strong></p>
    <hr></hr>
    <Attributes items={[{
      name: 'account_name',
      type: 'string',
      description: "The user's account name."
    }]} mdxType="Attributes" />
    <br />
    <br />
    <p><strong parentName="p">{`Body parameters`}</strong></p>
    <hr></hr>
    <p>{`The request expects a `}<a parentName="p" {...{
        "href": "#the-template-object"
      }}>{`template object`}</a>{` as its body request. Check the example request below for more information.`}</p>
    <br />
    <p><strong parentName="p">{`Request`}</strong></p>
    <hr></hr>
    <p>{`In the following example the API retrieve information pertaining to the Employee template, located in “My Templates” for the account “SmartVault”.`}</p>
    <br />
    <Tabs items={[{
      tabTitle: 'Raw',
      tabInfo: createTemplateRaw
    }, {
      tabTitle: 'cURL',
      tabInfo: createTemplateCurl
    }, {
      tabTitle: 'Java',
      tabInfo: createTemplateJava
    }, {
      tabTitle: 'Javascript',
      tabInfo: createTemplateJavascript
    }, {
      tabTitle: 'Node',
      tabInfo: createTemplateNode
    }, {
      tabTitle: 'Perl',
      tabInfo: createTemplatePerl
    }, {
      tabTitle: 'Python',
      tabInfo: createTemplatePython
    }, {
      tabTitle: 'PHP',
      tabInfo: createTemplatePhp
    }, {
      tabTitle: 'Ruby',
      tabInfo: createTemplateRuby
    }, {
      tabTitle: 'Go',
      tabInfo: createTemplateGo
    }, {
      tabTitle: 'C#',
      tabInfo: createTemplateC
    }, {
      tabTitle: 'Visual Basic',
      tabInfo: createTemplateVB
    }, {
      tabTitle: 'Groovy',
      tabInfo: createTemplateGroovy
    }, {
      tabTitle: 'Objective-C',
      tabInfo: createTemplateObjectiveC
    }, {
      tabTitle: 'Swift',
      tabInfo: createTemplateSwift
    }]} mdxType="Tabs" />
    <br />
    <p><strong parentName="p">{`Response`}</strong></p>
    <hr></hr>
    <p>{`Returns a `}<a parentName="p" {...{
        "href": "/api/terminology#node-response"
      }}>{`node response object`}</a>{` pointing to the template retrieved.`}</p>
    <Expander title="success object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": true
    },
    "message":
    {
        "name": "TaxEngagementCustomTemplate",
        "createdOn": "2020-03-07T15:34:58-05:00",
        "modifiedOn": "2020-03-07T15:34:58-05:00",
        "uri": "/nodes/template/SmartVault Account/MyTemplates/TaxEngagementCustomTemplate",
        "your_permissions": 81
    }
}
`}</code></pre>
    </Expander>
    <br />
    <br />
    <br />
    <br />
    <hr />
    <br />
    <br />
    <br />

    <h2 {...{
      "id": "update-a-template"
    }}>{`Update a template`}</h2>
    <Row mdxType="Row">
	<div>
		SmartVault also allows the modification of user created templates with a POST method.
		<br />
	</div>
	<Table title="Update a template" rows={updateTemplate} mdxType="Table" />
    </Row>
    <br />
This endpoint can be used for multiple modifications of the template.
    <br />
    <br />
    <List values={[{
      title: 'Copying templates'
    }, {
      title: 'Moving template nodes'
    }, {
      title: 'Merging template nodes'
    }, {
      title: 'Deleting template nodes'
    }, {
      title: 'Adding folders to templates'
    }, {
      title: 'Setting notifications'
    }, {
      title: 'Routing documents to template nodes (tags)'
    }]} mdxType="List" />
    <br />
Find more information about each type of request below.
    <br />
    <br />
    <br />
    <p><strong parentName="p">{`Parameters`}</strong></p>
    <hr />
    <Attributes items={[{
      name: 'account_name',
      type: 'string',
      description: "The user's account name."
    }, {
      name: 'entity_definition',
      type: 'string',
      description: 'The template name.'
    }]} mdxType="Attributes" />
    <br />
    <br />
    <p><strong parentName="p">{`Body parameters`}</strong></p>
    <hr />
    <p>{`Use the following properties to make changes to a template`}</p>
    <Expander mdxType="Expander">
	<Attributes items={[{
        name: 'update',
        type: 'UpdateTemplateRequest (optional)',
        description: 'Update a template.'
      }, {
        name: 'copy',
        type: 'CopyTemplateRequest (optional)',
        description: 'Make a copy of a template.'
      }, {
        name: 'change',
        type: 'ApplyChangeSet (optional)',
        description: 'Make changes to a template.'
      }, {
        name: 'apply_template',
        type: 'boolean (optional)',
        description: 'Indicates whether you want to apply the template to its corresponding entity/entities.'
      }]} mdxType="Attributes" />
    </Expander>
    <br />
    <br />
    <h3 {...{
      "id": "the-updatetemplaterequest-object"
    }}>{`The UpdateTemplateRequest object`}</h3>
    <hr style={{
      "width": "50%"
    }} />
    <p>{`Use the update container to change the properties associated with a template (API name for the template, label for the template, who the template applies to, etc).`}</p>
    <Expander title="UpdateTemplateRequest" mdxType="Expander">
	<Attributes items={[{
        name: 'update_api_name',
        type: 'boolean (optional)',
        description: 'Indicates if the template API name will be updated.'
      }, {
        name: 'api_name',
        type: 'string (optional)',
        description: 'How the template will be stored as in the API. (update_api_name must be set to true if this field is being modified).'
      }, {
        name: 'update_label',
        type: 'boolean (optional)',
        description: 'Indicates if the template label will be updated.'
      }, {
        name: 'label',
        type: 'string (optional)',
        description: 'The new name of the template in the client portal. (update_label must be set to true if this field is being modified).'
      }, {
        name: 'update_plural_label',
        type: 'boolean (optional)',
        description: 'Indicates if the template plural label will be updated.'
      }, {
        name: 'plural_label',
        type: 'string (optional)',
        description: 'The new value for the plural label. (update_plural_label must be set to true if this field is being modified).'
      }]} mdxType="Attributes" />
    </Expander>
    <br />
    <br />
    <br />
    <h3 {...{
      "id": "copying-templates"
    }}>{`Copying Templates`}</h3>
    <hr style={{
      "width": "50%"
    }} />
    <p>{`When copying templates, you will need to provide the following body parameter.`}</p>
    <Attributes items={[{
      name: 'dst_uri',
      type: 'string',
      description: 'Destination URI of the new copy of the template.'
    }]} mdxType="Attributes" />
    <br />
    <br />
    <br />
    <h3 {...{
      "id": "modifying-template-information-the-applychangeset-object"
    }}>{`Modifying Template Information (The ApplyChangeSet object)`}</h3>
    <hr style={{
      "width": "50%"
    }} />
    <p>{`Use the change container to make changes to a template’s metadata. The properties for ApplyChangeSet are.`}</p>
    <Expander title="ApplyChangeSet attributes" mdxType="Expander">
	<Attributes items={[{
        name: 'version',
        type: 'int',
        description: "Value for the new version of the template. Can't be lower or the same value as the last version used.\r\nMore info on how to retrieve this below."
      }, {
        name: 'comments',
        type: 'string',
        description: 'Comments describing changes being applied.'
      }, {
        name: 'do_not_save',
        type: 'boolean (optional)',
        description: 'Checks for the changes not to be saved.'
      }, {
        name: 'apply_template',
        type: 'boolean (optional)',
        description: 'Indicates if you want to apply the template to its corresponding entity/entities.'
      }, {
        name: 'changes',
        type: 'List<TemplateChange> (optional)',
        description: 'Collection of changes being applied to the template.\r\nMore info about the TemplateChange type below.'
      }]} mdxType="Attributes" />
    </Expander>
    <br />
    <InfoIcon mdxType="InfoIcon" />
To retrieve the current version (and know what value you should specify for the update) make a request to
    <div>
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`/nodes/template/{account_name}/My Templates/{entity_definition}?eprop=true
`}</code></pre>
    </div>
    <br /><br /><br />
    <h4 {...{
      "id": "the-templatechange-object"
    }}>{`The TemplateChange object`}</h4>
    <p>{`A TemplateChange is a union of possible changes made to a template.`}<br />{`
Each change should apply at most one change to the template and most changes apply to one aspect of the template, specified by the ‘selector’ field.`}<br />{`
One of the few exceptions is changing the template label, which is a global template change and does not require a selector.`}</p>
    <Expander title="TemplateChange attributes" mdxType="Expander">
	<Attributes items={[{
        name: 'selector',
        type: 'string',
        description: 'Selects a node to apply the change to. It consists of the path to this node in the template. It is a forward slash delimited path composed of the ids from each element. (e.g. the path to a folder called id "my_folder" inside a container of id "my_container" inside a root vault of id "root" will be /root/my_container/my_folder).'
      }, {
        name: 'move',
        type: 'string (optional)',
        description: 'Move the selector node to this new destination.'
      }, {
        name: 'merge',
        type: 'string (optional)',
        description: 'Merge the selected node with this node. In a merge operation, the contents, along with the associations and relationships of the source, are combined with the target.'
      }, {
        name: 'folder_association',
        type: 'FolderAssociation (optional)',
        description: 'Add or delete routing rules from the selector node. The same tag can only belong to at most 1 node. A tag belongs to the last node to which it was added. Thus, it is not necessary to explicitly delete a tag before adding a tag to another location.'
      }, {
        name: 'delete',
        type: 'List<boolean> (optional)',
        description: "Sets whether or not to delete this node. It's a destructive, recursive deletion."
      }, {
        name: 'add',
        type: 'TemplateFolder (optional)',
        description: 'Add a folder to the template.'
      }, {
        name: 'set_acl',
        type: 'UpdateContainer (optional)',
        description: 'Updates the acl of the target node.'
      }, {
        name: 'rename',
        type: 'string (optional)',
        description: 'Value to set as new name for the node.'
      }, {
        name: 'add_relationship',
        type: 'List<string> (optional)',
        description: 'Add a relationship to the selected node. If an add_relationship is applied to a relationship that already exists in the template application a delete_relationship is done automatically.'
      }, {
        name: 'delete_relationship',
        type: 'List<string>',
        description: 'Delete an existing relationship form the selected node.'
      }, {
        name: 'change_label',
        type: 'string (optional)',
        description: 'Change the label of the template to this. This is the only change that does not require a selector, as it applies to the entire template.'
      }, {
        name: 'change_id',
        type: 'string (optional)',
        description: 'Change the id of the node to this value. Changes are applied in order and all changes after this one should refer to the change by the new id.'
      }, {
        name: 'set_notifications',
        type: 'SetNotifications (optional)',
        description: 'Set the notifications for this node.'
      }]} mdxType="Attributes" />
    </Expander>
    <br />
    <br />
    <InfoIcon mdxType="InfoIcon" />For example, if you wanted to create a new nested folder on a template, you won't specify the "selector" value as "root", instead you can check for the current available selectors by making a request to the actual template.
    <div>
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`GET request to /nodes/template/SmartVault Account/My Templates/SmartVault.Accounting.FinancialServicesEngagementTemplate?eprop=true
`}</code></pre>
    </div>
    <br />
    <p>{`This will return the extra information of the template where you can check the selectors`}</p>
    <Expander title="template object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": true
    },
    "message": {
        "name": "SmartVault.Accounting.FinancialServicesEngagementTemplate",
        "createdOn": "2020-07-09T11:45:50-05:00",
        "modifiedOn": "2020-07-09T11:45:50-05:00",
        "uri": "/nodes/template/SmartVault Account/My Templates/SmartVault.Accounting.FinancialServicesEngagementTemplate",
        "your_permissions": 81,
        "templateExProperties": {
            "applies_to": "SmartVault.Accounting.FinancialServicesEngagement",
            "api_name": "SmartVault.Accounting.FinancialServicesEngagementTemplate",
            "label": "Financial Services Template",
            "groups": [
                {
                    "moniker": "FirmManager",
                    "name": "Firm Managers",
                    "rid": 8,
                    "create": true
                },
                {
                    "moniker": "FirmEmployees",
                    "name": "Firm Employees",
                    "rid": 9,
                    "create": true
                },
                {
                    "moniker": "VaultFullInbox",
                    "name": "Vault Full Inbox",
                    "rid": 10,
                    "create": true
                },
                {
                    "moniker": "ClientContacts",
                    "name": "Client Contacts",
                    "rid": 16,
                    "create": true
                },
                {
                    "moniker": "AccountCustomGroup1",
                    "name": "CustomGroup1",
                    "rid": 19,
                    "create": false
                },
                {
                    "moniker": "AccountCustomGroup2",
                    "name": "CustomGroup2",
                    "rid": 20,
                    "create": false
                },
                {
                    "moniker": "AccountCustomGroup3",
                    "name": "CustomGroup3",
                    "rid": 21,
                    "create": false
                },
                {
                    "moniker": "AccountAdmins",
                    "name": "Administrators",
                    "rid": 1,
                    "create": false
                },
                {
                    "moniker": "AccountEmployees",
                    "name": "Employees",
                    "rid": 18,
                    "create": false
                }
            ],
            "folders": [
                {
                    "name": "replace:Financial Services %YEAR%",
                    "acl": {
                        "aces": [
                            {
                                "principal": "AccountEmployees",
                                "permissions": 15
                            }
                        ]
                    },
                    "id": "root",
                    "folders": [
                        {
                            "name": "New Folder For Template",
                            "acl": {
                                "aces": [
                                    {
                                        "principal": "AccountEmployees",
                                        "permissions": 15
                                    }
                                ]
                            },
                            "id": "folder0",
                            "folders": [
                                {
                                    "name": "New New Folder 2",
                                    "acl": {
                                        "aces": [
                                            {
                                                "principal": "AccountEmployees",
                                                "permissions": 15
                                            }
                                        ]
                                    },
                                    "id": "folder1"
                                }
                            ]
                        }
                    ],
                    "folder_association": {
                        "tags": [
                            {
                                "value": "misc.tags.core.smartvault.com"
                            }
                        ]
                    }
                }
            ],
            "version": 3,
            "latest": true
        }
    }
}
`}</code></pre>
    </Expander>
    <br /><br /><br />
    <h4 {...{
      "id": "tags"
    }}>{`Tags`}</h4>
    <p>{`Using the folder_association element, you can add and delete routing rules from a template node.`}<br />{`
Documents published to the template entity that include these tags will be routed to this location.
Each routing rule should only occur at most once in the entire hierarchy.`}</p>
    <br />
    <br />
    <br />
    <h3 {...{
      "id": "merging-template-nodes"
    }}>{`Merging Template Nodes`}</h3>
    <hr style={{
      "width": "50%"
    }} />
    <p>{`The Merge operation merges the selected node to the destination node and combines associations, relationships, and contents of the source folder with the target.`}</p>
    <Expander title="merge properties" mdxType="Expander">
	<Attributes items={[{
        name: 'destination',
        type: 'string (optional)',
        description: 'Location of target node to merge with.'
      }]} mdxType="Attributes" />
    </Expander>
    <br />
    <br />
    <br />
    <h3 {...{
      "id": "setting-notification-for-nodes"
    }}>{`Setting notification for nodes`}</h3>
    <hr style={{
      "width": "50%"
    }} />
    <p>{`Property "set_notifications" updates the notifications of the target node. It consists of a property called "notifications", which is composed of an element also called "notifications", which houses the list of notifications to be updated.`}<br />{`
A notification has the following properties:`}</p>
    <Expander title="merge properties" mdxType="Expander">
	<Attributes items={[{
        name: 'principal',
        type: 'string',
        description: 'Defines who receives the notification. For templates, this is restricted to group monikers.'
      }, {
        name: 'notifications',
        type: 'int',
        description: 'Notifications that will be received by the principal.'
      }]} mdxType="Attributes" />
	<br />
	<List values={[{
        title: 'Download files',
        value: 'Triggered on the download of a file.\r\nValue: 1.'
      }, {
        title: 'Upload files',
        value: 'Triggered on the successful creation or update of file contents.\r\nValue: 2.'
      }]} mdxType="List" />
    </Expander>
    <br />
    <br />
    <p><strong parentName="p">{`Request`}</strong></p>
    <hr></hr>
    <p>{`Example of a call to add a new folder to a template`}</p>
    <Expander title="folder addition" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`// Add new folder for Financial Services Template
// POST to /nodes/template/SmartVault Account/My Templates/SmartVault.Accounting.FinancialServicesEngagementTemplate
{
   "change":{
      "version":2, // We are assuming it's the first time modifying this template; equals to 2 as we need to update the version to +1 for every update
      "comment":"Description of the change",
      "changes":[
         {
            "selector":"/root",
            "add":{
               "name":"New Folder",
               "acl":{
                  "aces":[
                     {
                        "principal":"AccountEmployees",
                        "permissions":15
                     }
                  ]
               },
               "id":"folder0" // New ID for the created folder
            }
         },
         {
            "selector":"/root/folder0",
            "rename":"New Folder For Template"
         }
      ],
      "apply_template":true
   }
}
`}</code></pre>
    </Expander>
    <br />
    <br />
    <p>{`Example of a call to modify folder permissions inside a template. More info about the permissions values `}<a parentName="p" {...{
        "href": "#template-permissions"
      }}>{`here`}</a>{`.`}</p>
    <Expander title="permissions modification" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`// Update permissions of Investment template
// POST to /nodes/template/SmartVault Account/My Templates/SmartVault.Accounting.InvestmentEngagementTemplate
{
   "change":{
      "version":2,
      "comment":"Brief description of changes.",
      "changes":[
         {
            "selector":"/root",
            "set_acl":{
               "acl":{
                  "aces":[
                     {
                        "principal":"AccountEmployees",
                        "permissions":15 // Read Write Create Delete
                     },
                     {
                        "principal":"FirmManager",
                        "permissions":3 // Read Write
                     },
                     {
                        "principal":"FirmEmployees",
                        "permissions":7 // Read Write Create
                     },
                     {
                        "principal":"VaultFullInbox",
                        "permissions":15 // Read Write Create Delete
                     },
                     {
                        "principal":"ClientContacts",
                        "permissions":47 // Read Write Create Delete View access
                     },
                     {
                        "principal":"AccountCustomGroup1",
                        "permissions":111 // Full access
                     },
                     {
                        "principal":"AccountCustomGroup2",
                        "permissions":37 // Read Create View access
                     },
                     {
                        "principal":"AccountCustomGroup3",
                        "permissions":74 // Write Delete Change access
                     },
                     {
                        "principal":"AccountAdmins",
                        "permissions":0 // None
                     }
                  ]
               }
            }
         }
      ],
      "apply_template":true
   }
}
`}</code></pre>
    </Expander>
    <br />
    <br />
    <p>{`Example for a template modification request execution.`}</p>
    <br />
    <Tabs items={[{
      tabTitle: 'Raw',
      tabInfo: updateTemplateRaw
    }, {
      tabTitle: 'cURL',
      tabInfo: updateTemplateCurl
    }, {
      tabTitle: 'Java',
      tabInfo: updateTemplateJava
    }, {
      tabTitle: 'Javascript',
      tabInfo: updateTemplateJavascript
    }, {
      tabTitle: 'Node',
      tabInfo: updateTemplateNode
    }, {
      tabTitle: 'Perl',
      tabInfo: updateTemplatePerl
    }, {
      tabTitle: 'Python',
      tabInfo: updateTemplatePython
    }, {
      tabTitle: 'PHP',
      tabInfo: updateTemplatePhp
    }, {
      tabTitle: 'Ruby',
      tabInfo: updateTemplateRuby
    }, {
      tabTitle: 'Go',
      tabInfo: updateTemplateGo
    }, {
      tabTitle: 'C#',
      tabInfo: updateTemplateC
    }, {
      tabTitle: 'Visual Basic',
      tabInfo: updateTemplateVB
    }, {
      tabTitle: 'Groovy',
      tabInfo: updateTemplateGroovy
    }, {
      tabTitle: 'Objective-C',
      tabInfo: updateTemplateObjectiveC
    }, {
      tabTitle: 'Swift',
      tabInfo: updateTemplateSwift
    }]} mdxType="Tabs" />
    <br />
    <p><strong parentName="p">{`Response`}</strong></p>
    <hr></hr>
    <p>{`Returns a `}<a parentName="p" {...{
        "href": "/api/terminology#node-response"
      }}>{`node response object`}</a>{` pointing to the template updated.`}</p>
    <Expander title="success object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": true
    },
        "message": {
        "name": "SmartVault.Core.UserTemplate",
        "createdOn": "2015-06-04T13:54:37-05:00",
        "modifiedOn": "2015-06-04T13:54:37-05:00",
        "uri": "/nodes/template/SmartVault Account/My Templates/SmartVault.Core.UserTemplate",
        "your_permissions": 81
    }
}
`}</code></pre>
    </Expander>
    <br />
    <p>{`Returns an `}<a parentName="p" {...{
        "href": "/api/errors#error-object"
      }}>{`error object`}</a>{` if the template trying to be updated doesn't exist.`}</p>
    <Expander title="error object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": false,
        "error_code": -520093684,
        "error_text": "Object not found.",
        "instance_guid": "506c9572-bf49-4147-b3fe-dac45dae5dd9",
        "status_code": 404
    }
}
`}</code></pre>
    </Expander>
    <br />
    <br />
    <br />
    <br />
    <hr />
    <br />
    <br />
    <br />
    <h2 {...{
      "id": "template-permissions"
    }}>{`Template permissions`}</h2>
    <p>{`SmartVault allows the modification of the permissions in templates. You can find right below the required objects to update the permissions of a node as well as some examples for better understanding.`}<br /></p>
    <br />
    <Row codeExamples style={{
      "justifyContent": "flex-start"
    }} mdxType="Row">
	<div style={{
        "background": "rgba(0, 0, 0, 0.25)",
        "padding": "0.5rem 2rem 2rem",
        "borderRadius": "1rem",
        "flex": "0",
        "minWidth": "15rem"
      }}>
		<h4>The UpdateContainer object</h4>
		<Attributes items={[{
          name: 'aces',
          type: 'List<UpdateACE>',
          description: 'List of access control entries.'
        }]} mdxType="Attributes" />
	</div>
	<div style={{
        "background": "rgba(0, 0, 0, 0.25)",
        "padding": "0.5rem 2rem 2rem",
        "borderRadius": "1rem",
        "flex": "0",
        "minWidth": "15rem"
      }}>
		<h4>The UpdateACE object</h4>
		<Attributes items={[{
          name: 'principal',
          type: 'string',
          description: 'The entity to which the permissions apply to.'
        }, {
          name: 'permissions',
          type: 'int',
          description: 'New permissions value. Check table below.'
        }]} mdxType="Attributes" />
	</div>
    </Row>
    <br />
    <br />
    <p>{`Possible values for the permissions field of the UpdateACE. It can be each value individually or the sum of any of them.`}<br />{`
e.g.: "permissions: 3" would be read (1) and write (2) access.`}</p>
    <Expander title="permissions value" mdxType="Expander">
	<List inline values={[{
        title: 'Read: 1'
      }, {
        title: 'Write: 2'
      }, {
        title: 'Create: 4'
      }, {
        title: 'Delete: 8'
      }, {
        title: 'View access: 32'
      }, {
        title: 'Change access: 64'
      }, {
        title: 'Full: 111'
      }]} mdxType="List" />
    </Expander>
    <br />
    <br />
    <p>{`Knowing this, if we wanted to add write, create and change access permission to a certain group member in a template, it'd be something like the following`}</p>
    <InfoIcon mdxType="InfoIcon" /> More info about the rest of the body parameters <a target="_blank" href="/api/templates#modifying-template-information-the-applychangeset-object">
	here
    </a>.
    <br />
    <br />
    <Expander title="example" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`// Request endpoint: /nodes/template/SmartVault Account/My Templates/SmartVault.Core.UserTemplate
// Request body:
{
   "change":{
      "version":2,
      "comment":"Description of the permissions changes.",
      "changes":[
         {
            "selector":"/root",
            "set_acl":{
               "acl":{
                  "aces":[
                     {
                        "principal":"AccountAdmins",
                        "permissions":70 // Write (2) + Create(4) + Change access (64)
                     }
                  ]
               }
            }
         }
      ],
      "apply_template":true
   }
}
`}</code></pre>
    </Expander>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      